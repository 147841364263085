import React from "react";
import axios from "axios";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Divider,
  Card,
  Select,
  message
} from "antd";

const EditableContext = React.createContext();
const Option = Select.Option;
class EditableCell extends React.Component {
  state = {
    setValues: [],
    onChange: false,
    valid: false
  };
  getInput = utility_data => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    } else if (this.props.inputType == "utilityData") {
      return (
        <Select
          mode="multiple"
          style={{ width: 250 }}
          onChange={this.handleValueChange}
        >
          {utility_data.map((list, i) => {
            return <Option value={list._id}>{list.name}</Option>;
          })}
        </Select>
      );
    }
    return <Input />;
  };
  handleValueChange = value => {
    if (value.length == 0) {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
    this.setState({ setValues: value, onChange: true });
  };
  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      utility_data,
      ...restProps
    } = this.props;

    const setValuesOne = [];

    return (
      <td {...restProps}>
        {editing ? (
          dataIndex == "utilityData" ? (
            <div>
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator(dataIndex, {
                  rules: [
                    {
                      required: true,
                      message: `Please Select ${title}!`
                    }
                  ],
                  initialValue: this.state.onChange
                    ? this.state.setValues
                    : setValuesOne
                })(this.getInput(utility_data))}
              </Form.Item>
            </div>
          ) : (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`
                  }
                ],
                initialValue: record[dataIndex]
              })(this.getInput())}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], utility_data: [], editingKey: "" };
    this.columns = [
      {
        title: "Manufactre Name",
        dataIndex: "manufacture",
        width: "25%",
        editable: true
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        width: "25%",
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        width: "25%",
      },
      {
        title: "Action",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <a
                    onClick={() => this.save(form, record._id)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record._id)}
              >
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <a
                disabled={editingKey !== ""}
                onClick={() => this.edit(record._id)}
              >
                Edit
              </a>
            </span>
          );
        }
      }
    ];
  }
  componentDidMount() {
    axios
      .get("/manufactures")
      .then(response => {
        this.setState({ data: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
    axios
      .get("/utility")
      .then(response => {
        this.setState({ utility_data: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
  isEditing = record => record._id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save(form, _id) {
    console.log("ntered")
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => _id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
      axios
      .put("/edit/manufacture",newData[index])
      .then(response => {
        this.setState({ data: newData, editingKey: "" });
        message.success(("Edited Successfully"));
      })
      .catch(error => {
        console.log(error.response);
        message.error((error.response && error.response.data && error.response.data.message || "Network Error"));
      });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(_id) {
    this.setState({ editingKey: _id });
  }
  delete(_id) {
    console.log("checking the delete method@@@", _id);
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex,
          dataIndex: col.dataIndex,
          utility_data: this.state.utility_data,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    return (
      <div>
        <Card className="gx-card" title="All Devices">
          <EditableContext.Provider value={this.props.form}>
            <Table
              components={components}
              bordered
              dataSource={this.state.data}
              columns={columns}
              rowClassName="editable-row"
              pagination={{
                onChange: this.cancel
              }}
            />
          </EditableContext.Provider>
        </Card>
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;
