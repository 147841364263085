import React from "react";
import {Col, Row,Button} from "antd";
import DeviceList from './DeviceList/devicelist'
class Template extends React.Component{
  handleClick = this.handleClick.bind(this)
  handleClick(e){
    var url = window.location.href.split("/")
    var len = url.length
    url[len-1] = "add/device"
    var getUrl =  url.splice(3,6).join("/")
    this.props.history.push(`/${getUrl}`);
  }
  render(){
    return (
      <div>
      <Button type="primary" onClick={this.handleClick} style={{float: 'right','z-index' :'1','top':'10px'}}>Add New Device</Button>
      <div>
      <DeviceList/>
      </div>
      </div>

    );
  }
};

export default Template;
