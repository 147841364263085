import {
  GET_ALL_JOBS,
  GET_ALL_JOBS_SUCCESS,
  UPDATE_SYNC_STATUS,
} from "constants/ActionTypes";

export const get_all_jobs = () => {
  return {
    type: GET_ALL_JOBS,
  };
};

export const get_all_jobs_success = (payload) => {
  return {
    type: GET_ALL_JOBS_SUCCESS,
    payload: payload,
  };
};

export const change_sync_status = (payload) => {
  return {
    type: UPDATE_SYNC_STATUS,
    payload: payload,
  };
};
