import React, { Component } from "react";
import axios from "axios";
import {
  Modal,
  Upload,
  AutoComplete,
  Button,
  Card,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  Switch,
  Timeline,
  Radio
} from "antd";
import { withRouter } from "react-router-dom";

const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;

class Registration extends Component {
  page_name = "Register project";
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    utilityTemplate: [],
    projectMaintenance: {
      maintenance: "STANDARD",
      maintenanceBillType: "Daily",
      maintenanceType: "Pre-Paid"
    },
    isEdit: false,
    previewVisible: false,
    previewImage: "",
    fileList: [],
    projectData: {
      name: "",
      website: "",
      address: ""
    }
  };

  /*****************************************  MOUNT LOGIC  **************************************************/

  componentDidMount() {
    axios
      .get("/utility")
      .then(response => {
        this.setState({ utilityTemplate: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
    if (this.props.user) {
      this.page_name = "Edit project";
      var utilityData;
      axios
        .get("/utility")
        .then(response => {
          utilityData = response.data.data;
          axios
            .get(`/project/${this.props.user}`)
            .then(getRes => {
              var res = getRes.data.data.getProjectData;
              var utilitiesNames = [];
              var filteredUtilities = [];
              if (res.template.length > 0) {
                res.template.map(list => {
                  var setObj = {
                    value: true,
                    type: list.utilityType,
                    name: list.utilityId.name,
                    code: list.utilityId.code
                  };
                  utilitiesNames.push(list.utilityId.code);
                  filteredUtilities.push(setObj);
                });
                utilityData.map(utilityList => {
                  if (!utilitiesNames.includes(utilityList.code)) {
                    filteredUtilities.push(utilityList);
                  }
                });
              }

              this.setState({
                projectData: res,
                utilityTemplate: filteredUtilities,
                projectMaintenance: getRes.data.data.projectMaintenance
              });
              var logo = []
              if (res.imageUrl != "" || res.imageUrl) {
                logo = [{
                  uid: '-1',
                  name: 'image.png',
                  status: 'old',
                  url: res.imageUrl || ""
                }];
              }
              this.setState({ fileList: logo });
              this.setState({ isEdit: true });
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  /*****************************************  MOUNT LOGIC  **************************************************/

  onClickPaymentMode(e, index) {
    var getList = this.state.utilityTemplate;
    getList[index].type = e.target.value;
    this.setState({ utilityTemplate: getList });
  }
  onClickMaintenanceMode(e) {
    var getMaintenance = this.state.projectMaintenance;
    if (e.target.name == "Maintenance") {
      getMaintenance.maintenance = e.target.value;
      this.setState({ projectMaintenance: getMaintenance });
    } else if (e.target.name == "MaintenanceBillType") {
      getMaintenance.maintenanceBillType = e.target.value;
      this.setState({ projectMaintenance: getMaintenance });
    } else if (e.target.name == "MaintenanceType") {
      getMaintenance.maintenanceType = e.target.value;
      this.setState({ projectMaintenance: getMaintenance });
    }
  }
  onCheckMode(e, index) {
    var getList = this.state.utilityTemplate;
    if (e) {
      getList[index].value = true;
    } else {
      getList[index].value = false;
    }
    this.setState({ utilityTemplate: getList });
  }

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = [".com", ".org", ".net", ".in"].map(
        domain => `${value}${domain}`
      );
    }
    this.setState({ autoCompleteResult });
  };

  /*****************************************  SUBMIT LOGIC  **************************************************/

  handleSubmit = e => {
    e.preventDefault();
    var template = this.state.utilityTemplate;
    // var Maintenance
    var imageUrl = this.state.fileList;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var utilityArr = [];
        template.map(list => {
          if (list.value) {
            var utilityObj = {};
            utilityObj.utilityType = list.type;
            utilityObj.utilityId = list._id;
            utilityArr.push(utilityObj);
          }
        });
        values.template = utilityArr;
        values.imageUrl = imageUrl;

        if (!this.state.isEdit) {
          values.projectMaintenance = this.state.projectMaintenance;
          axios
            .post("/project", values)
            .then(response => {
              this.props.history.push(`/components/project`);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          values.projectId = this.props.user;
          delete values.template;
          axios
            .put("/project", values)
            .then(response => {
              this.props.history.push(`/components/project`);
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    });
  };
  /*****************************************  SUBMIT LOGIC  **************************************************/

  /*****************************************  IMAGE LOGIC  **************************************************/

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  /*****************************************  IMAGE LOGIC  **************************************************/
  render() {
    if (this.state.projectMaintenance !== undefined) {
      var getMaintenance = this.state.projectMaintenance;
    }
    const utilityTemplate = this.state.utilityTemplate;
    const { getFieldDecorator } = this.props.form;
    const {
      autoCompleteResult,
      projectData,
      previewVisible,
      previewImage,
      fileList
    } = this.state;
    const plainOptions = ["Pre-Paid", "Post-Paid"];
    const MaintenanceType = ["Pre-Paid", "Post-Paid"];
    const MaintenanceBillType = ["Daily", "Monthly"];
    const Maintenance = ["STANDARD", "BHK", "SFT"];
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));
    const templateStyle = {
      float: "left",
      width: "80px"
    };
    const templateDisplay = {
      display: "flex"
    };
    const projectMode = ["lora", "modbus-wired"];
    return (
      <Card className="gx-card" title={this.page_name}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Name&nbsp;
                <Tooltip title="This is your project name">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("projectName", {
              initialValue: projectData.name,
              rules: [
                {
                  required: true,
                  message: "Please input your Project Name!",
                  whitespace: true
                }
              ]
            })(
              <AutoComplete placeholder="Project Name">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Address">
            {getFieldDecorator("residence", {
              initialValue: projectData.address,
              rules: [{ required: true, message: "Please enter address!" }]
            })(
              <AutoComplete placeholder="Address" value="Siddhartha">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Username">
            {getFieldDecorator("username", {
              initialValue: projectData.username,
              rules: [{ required: true, message: "Please enter username!" }]
            })(
              <AutoComplete placeholder="Address">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Password">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please enter password!" }]
            })(
              <AutoComplete placeholder="Address">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Website">
            {getFieldDecorator("website", {
              initialValue: projectData.website,
              rules: [{ message: "Please input website!" }]
            })(
              <AutoComplete
                dataSource={websiteOptions}
                onChange={this.handleWebsiteChange}
                placeholder="website"
              >
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Project Type"
          >
            {getFieldDecorator('projectMode', {
              rules: [{ required: true, }],
            })(
              <AutoComplete
              >
                <Radio.Group
                  options={projectMode}
                  style={{ "margin-top": "10px" }}
                  name={"projectMode"}
                />
              </AutoComplete>
            )}
          </FormItem>

          <Col xs={{ span: 26 }} lg={{ span: 12, offset: 8 }}>
            <Card className="gx-card">
              <div style={templateDisplay}>
                <div>
                  <h4>Select Utility</h4>
                  <br></br>
                  {utilityTemplate.map((list, index) => {
                    return (
                      <div>
                        <div className="gx-mb-3">
                          <span style={templateStyle}>{list.name}</span>
                          <Switch
                            onChange={e => this.onCheckMode(e, index)}
                            disabled={this.state.isEdit}
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="cross" />}
                            checked={list.value}
                            value={list.code}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div style={{ "margin-left": "15px" }}>
                  <h4 style={{ "margin-left": "40px" }}>Mode of Payment</h4>
                  <br></br>
                  <Timeline style={{ padding: "3px" }}>
                    {utilityTemplate.map((list, i) => {
                      return (
                        <Timeline.Item style={{ "padding-bottom": "21px" }}>
                          <span style={{ "margin-left": "10px" }}></span>
                          <Radio.Group
                            options={plainOptions}
                            disabled={!list.value || this.state.isEdit}
                            onChange={e => this.onClickPaymentMode(e, i)}
                            name={list.code}
                            value={list.value ? list.type : ""}
                          />
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </div>
              </div>
              <div>Note : Utility cannot be edit once it is created.</div>
            </Card>
          </Col>
          <Col xs={{ span: 26 }} lg={{ span: 12, offset: 8 }}>
            <Card className="gx-card">
              <div>
                <div>
                  <h4>Maintenance</h4>
                  <br></br>
                  Select Maintenance type of payment
                  <br></br>
                  {
                    <Radio.Group
                      options={MaintenanceType}
                      style={{ "margin-top": "10px" }}
                      disabled={this.state.isEdit}
                      onChange={e => this.onClickMaintenanceMode(e)}
                      name={"MaintenanceType"}
                      value={getMaintenance.maintenanceType}
                    />
                  }
                  <br></br>
                  <br></br>
                  Select Maintenance deduction type
                  <br></br>
                  {
                    <Radio.Group
                      options={MaintenanceBillType}
                      style={{ "margin-top": "10px" }}
                      disabled={this.state.isEdit}
                      onChange={e => this.onClickMaintenanceMode(e)}
                      name={"MaintenanceBillType"}
                      value={getMaintenance.maintenanceBillType}
                    />
                  }
                  <br></br>
                  <br></br>
                  Apply Maintenance on
                  <br></br>
                  {
                    <Radio.Group
                      options={Maintenance}
                      style={{ "margin-top": "10px" }}
                      disabled={this.state.isEdit}
                      onChange={e => this.onClickMaintenanceMode(e)}
                      name={"Maintenance"}
                      value={getMaintenance.maintenance}
                    />
                  }
                </div>
              </div>
            </Card>
          </Col>
          <Col span={12} offset={8}>
            <div>
              <h4>Upload Image</h4>
              <Upload
                beforeUpload={() => false}
                listType="picture-card"
                fileList={fileList}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
                onRemove={this.handleRemove}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Col>
          {this.state.isEdit ? (
            ""
          ) : (
            <FormItem {...tailFormItemLayout}>
              {getFieldDecorator("agreement", {
                valuePropName: "checked"
              })(
                <Checkbox>
                  I have read the <span className="gx-link">agreement</span>
                </Checkbox>
              )}
            </FormItem>
          )}

          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const RegistrationForm = Form.create()(Registration);
export default withRouter(RegistrationForm);
