import React, {Component} from "react";
import axios from 'axios'
import {Button, Card, Form, Icon, Input} from "antd";

import "./utility.less";

const FormItem = Form.Item;

let uuid = 0;

class DynamicFormItem extends Component {


  remove = (k) => {
    const {form} = this.props;
    // can use data-binding to get

    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const {form} = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(uuid);
    uuid++;
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var individual_arr = []
        values.names.map(list=>{
          individual_arr.push(list)
        })
        let leftSide = individual_arr.splice(0, Math.ceil(individual_arr.length /2));
        let rightSide = individual_arr;
        var combined_arr = []
        leftSide.map((list,i)=>{
          var obj = {}
              obj.name = leftSide[i]
              obj.code = rightSide[i]
              combined_arr.push(obj)
        })
        var sendObj = {
          utilityData : combined_arr
        }
        axios.post('/utility',sendObj)
        .then(response => {
          this.props.history.push(`/components/utility`);
        })
        .catch(error => {
          console.log(error);
        });

      }
    });
  };


  render() {

    const {getFieldDecorator, getFieldValue} = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 20, offset: 4},
      },
    };
    getFieldDecorator('keys', {initialValue: []});
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => {
      return (
        <div>
        <FormItem
          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? 'Utility Name' : 'Utility Name'}
          required={false}
          key={k}
        >
          {getFieldDecorator(`names[${k}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [{
              required: true,
              whitespace: true,
              message: "Please input Utility Name or delete this field.",
            }],
          })(
            <Input placeholder="Ex., Electricity, Water, Generator" style={{width: '60%', marginRight: 8}}/>
          )}
          {keys.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              disabled={keys.length === 1}
              onClick={() => this.remove(k)}
            />
          ) : null}
        </FormItem>
        <FormItem
          {...(index+100 === 100 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index+100 === 100 ? 'Utility Code' : 'Utility Code'}
          required={false}
          key={k+100}
        >
          {getFieldDecorator(`names[${k+100}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [{
              required: true,
              whitespace: true,
              message: "Please input Utility Code or delete this field.",
            }],
          })(
            <Input placeholder="Ex EB, DG , TWM , GW , GM" style={{width: '60%', marginRight: 8}}/>
          )}
        </FormItem>
        <hr></hr>
        </div>

      );
    });


    return (
      <Card className="gx-card" title="Add Utilities">
        <Form onSubmit={this.handleSubmit}>
          {formItems}
          <FormItem {...formItemLayoutWithOutLabel}>
            <Button type="dashed" onClick={this.add} style={{width: '60%'}}>
              <Icon type="plus"/> Add field
            </Button>
          </FormItem>
          <FormItem {...formItemLayoutWithOutLabel}>
            <Button type="primary" htmlType="submit">Submit</Button>
          </FormItem>
        </Form>
      </Card>
    );
  }

}

const WrappedDynamicFieldSet = Form.create()(DynamicFormItem);


export default WrappedDynamicFieldSet;






