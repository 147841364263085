import React, { useEffect, useState } from "react";
import { Table, message, Tag } from "antd";
import { connect } from "react-redux";
import { get_all_jobs } from "../../../appRedux/actions/Syncjob";
import Modal from "./Modal.js";

const columns = [
  {
    title: "Project Name",
    dataIndex: "projectData[0].name",
    width: "20%",
  },
  {
    title: "Project Mode",
    dataIndex: "projectData[0].projectMode",
    width: "20%",
  },
  {
    title: "Crone Lastupdate Date",
    dataIndex: "customerIndexDate",
    width: "20%",
  },
  {
    title: "Current Status",
    dataIndex: "syncStatus",
    width: "20%",
    render: (value) => {
      let color = value == "completed" ? "green" : "volcano";
      return (
        <Tag color={color} key={value}>
          {value}
        </Tag>
      );
    },
  },
  {
    title: "Button",
    width: "10%",
    render: (value, record) => {
      return <Modal record={record} />;
    },
  },
];

const SyncTable = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    props.get_all_jobs();
  }, []);

  useEffect(() => {
    setTableData(props.syncjob);
  }, [props.syncjob]);
  return (
    <div>
      <Table
        pagination
        bordered
        rowKey="_id"
        columns={columns}
        dataSource={tableData}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    syncjob: state.syncjob.sync_jobs,
    common: state.common,
  }),
  {
    get_all_jobs,
  }
)(SyncTable);
