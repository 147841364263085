import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class HorizontalNav extends Component {

  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  render() {
    const {pathname, navStyle} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (

      

      <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal">
      <MenuItemGroup
        key="main"
        className="gx-menu-group"
        title={<IntlMessages id="sidebar.main" />}
      >
          <Menu.Item key="main/dashboard/home">
            <Link to="/main/dashboard/home">
              <i className="icon icon-listing-dbrd" />
              Dashboard
            </Link>
          </Menu.Item>
      </MenuItemGroup>

      <MenuItemGroup
        key="components"
        className="gx-menu-group"
        title={<IntlMessages id="sidebar.components" />}
      >
        <Menu.Item key="components/project">
          <Link to="/components/project">
            <i className="icon icon-home" />
            <IntlMessages id="sidebar.dashboard.project" />
          </Link>
        </Menu.Item>
        <Menu.Item key="components/utility">
          <Link to="/components/utility">
            <i className="icon icon-select" />
            <IntlMessages id="sidebar.dashboard.template" />
          </Link>
        </Menu.Item>
        <Menu.Item key="components/device">
          <Link to="/components/device">
            <i className="icon icon-map-km-layer" />
            <IntlMessages id="sidebar.dashboard.device" />
          </Link>
        </Menu.Item>
        <Menu.Item key="components/mapdevice">
          <Link to="/components/mapdevice">
            <i className="icon icon-crm" />
            <IntlMessages id="sidebar.dashboard.devicemap" />
          </Link>
        </Menu.Item>
        <Menu.Item key="components/manufacture">
          <Link to="/components/manufacture">
            <i className="icon icon-crm" />
            Manufacture
          </Link>
        </Menu.Item>
      </MenuItemGroup>
    </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {themeType, navStyle, pathname, locale} = settings;
  return {themeType, navStyle, pathname, locale}
};
export default connect(mapStateToProps)(HorizontalNav);

