import React from "react";
import {Col, Row,Button} from "antd";
import Registration from "./Newproject/project"
class Crypto extends React.Component{
  user = this.props.match.params.id;
  render(){
    return (
      <div>
         <div>
         <Registration user={this.user}/>
         </div>

      </div>

    );
  }
};

export default Crypto;
