import React from "react";
import {
  Table,
  Input,
  Tag,
  InputNumber,
  Popconfirm,
  Form,
  Divider,
  Card,
  message
} from "antd";
import axios from "axios";

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      data: [],
      editingKey: "",
      isSuccessMsg: false,
      successMsg: ""
    };
    this.columns = [
      {
        title: "Serial No.",
        dataIndex: "device_id",
        width: "25%"
      },
      {
        title: "Project Name",
        dataIndex: "projectId.name",
        width: "25%"
      },
      {
        title: "Status",
        dataIndex: "in_use",
        width: "25%",
        render: value => {
          const color = value ? "green" : "volcano";
          const name = value ? "In-Use" : "Un-Use";
          return <span>{<Tag color={color}>{name}</Tag>}</span>;
        }
      },
      {
        title: "Action",
        dataIndex: "in_use",
        render: (value, record) => {
          return (
            <span>
              <Popconfirm
                title="Sure to Un-Map device from project?"
                onConfirm={() => this.delete(record._id)}
              >
                <a disabled={value}>Un-Map</a>
              </Popconfirm>
            </span>
          );
        }
      }
    ];
  }

  isEditing = record => record._id === this.state.editingKey;
  componentDidMount() {
    axios
      .get("/project/device/list")
      .then(response => {
        this.setState({ data: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
  cancel = id => {
    console.log("checking the ide###", id);
    //this.setState({ editingKey: "" });
  };

  save(form, _id) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => _id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.setState({ data: newData, editingKey: "" });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(_id) {
    this.setState({ editingKey: _id });
  }
  delete(_id) {
    const newData = [...this.state.data];
    const index = newData.findIndex(item => _id === item._id);
    if (index > -1) {
      axios
      .post("/device/unmap",newData[index])
      .then(response => {
        newData.splice(index, 1);
        this.setState({ data: newData});
        this.setState({isSuccessMsg : true, successMsg : "Successfully un mapped the device from project"})   
      })
      .catch(error => {
        console.log(error);
      });
    } 
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });

    return (
      <div>
        <Card className="gx-card" title="All Devices">
          <EditableContext.Provider value={this.props.form}>
            <Table
              components={components}
              bordered
              dataSource={this.state.data}
              columns={columns}
              rowClassName="editable-row"
              pagination={{
                onChange: this.cancel
              }}
            />
          </EditableContext.Provider>
        </Card>
        <div>
        {this.state.isSuccessMsg && message.success(this.state.successMsg)}
        </div>
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;
