import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER } from "../../constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess
} from "../../appRedux/actions/Auth";
import axios from "axios";

const signInUserWithEmailPasswordRequest = async payload =>
  await axios
    .post("/project/login", payload)
    .then(res => res.data)
    .catch(error => error.response);

// const signOutRequest = async () =>
//   await auth
//     .signOut()
//     .then((authUser) => authUser)
//     .catch((error) => error);

function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);
    console.log("###", signInUser);
    if (signInUser.status == "401") {
      yield put(showAuthMessage(signInUser.data.message));
    } else {
      localStorage.setItem("userData", JSON.stringify(signInUser.data));
      yield put(userSignInSuccess(signInUser.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem("userData");
    localStorage.clear();
    yield put(userSignOutSuccess("Success"));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
