import React, { Component } from "react";
import {
  AutoComplete,
  Button,
  Card,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  Switch,
  Timeline
} from "antd";
import axios from "axios";

const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;

class Registration extends Component {
  state = {
    options: [],
    devices: []
  };

  componentDidMount() {
    Promise.all([axios.get(`/project`), axios.get(`/device/details`)])
      .then(([projects, devices]) => {
        this.setState({
          options: projects.data.data,
          devices: devices.data.data
        });
      })
      .catch(error => console.log(error));
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        axios
          .post("/map/device/project", values)
          .then(response => {
            this.props.history.push(`/components/mapdevice`);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "86"
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    );

    return (
      <Card className="gx-card" title="Map Device">
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Select project&nbsp;
                <Tooltip title="Please select the project">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("projectId", {
              rules: [
                {
                  required: true,
                  message: "Please Select a Project"
                }
              ]
            })(
              <Select
                showSearch
                style={{ width: 600 }}
                placeholder="Select a project"
              >
                {this.state.options.map((list, i) => {
                  return <Option value={list._id}>{list.name}</Option>;
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={<span>Select Devices</span>}>
            {getFieldDecorator("devices", {
              rules: [
                {
                  required: true,
                  message: "Please Select at least one device"
                }
              ]
            })(
              <Select
                mode="multiple"
                style={{ width: 600 }}
                placeholder="Please select"
              >
                {this.state.devices.map((list, i) => {
                  return (
                    <Option key={list._id}>
                      {list.device_id}
                      {" - "}
                      {list.mac_id}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Map device
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const RegistrationForm = Form.create()(Registration);
export default RegistrationForm;
