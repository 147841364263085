import React, {Component} from "react";
import {AutoComplete, Button, Card, Form, Icon, Input, Row, Select, Tooltip,Switch,Timeline,message} from "antd";

import axios from 'axios'
const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;




class Registration extends Component {
  state = {
    utility : [],
  };

  componentDidMount(){
    axios.get('/utility')
    .then(response => {
     this.setState({utility : response.data.data})
    })
    .catch(error => {
      console.log(error);
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        delete values.prefix
        axios.post('/create/manufacture', values)
        .then(response => {
          this.props.history.push(`/components/manufacture`);
          message.success(("Successfully created"));
        })
        .catch(error => {
          console.log(error.response);
          message.error((error.response && error.response.data && error.response.data.message || "Network Error"));
        });
      }
    });
  }


  render() {
    const {getFieldDecorator} = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '86',
    })(
      <Select style={{width: 70}}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    );

    return (
      <Card className="gx-card" title="Register Device">
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label="Manufacturer Name"
          >
            {getFieldDecorator('manufacture', {
              rules: [{required: true, message: 'Please enter manufacturer name!'}],
            })(
              <AutoComplete
              placeholder="Manufacturer name"
            >
              <Input/>
            </AutoComplete>
            )}
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">Create</Button>
          </FormItem>
        </Form>
      </Card>
    );
  }

}

const RegistrationForm = Form.create()(Registration);
export default RegistrationForm;
