import React from "react";
import { Card } from "antd";
import SyncTable from "./SyncTable";

const Index = () => {
  return (
    <Card className="gx-card" title="Cron Job Status">
      <div className="gx-mt-5">
        <SyncTable />
      </div>
    </Card>
  );
};

export default Index;
