import React from "react";
import { Route, Switch } from "react-router-dom";

import DataDisplay from "./dataDisplay";
import DataEntry from "./dataEntry";
import Feedback from "./feedback";
import Navigation from "./navigation";
import Others from "./others";
import General from "./general";
import Table from "./table";
import Project from "./Project/index";
import AddProject from "./Project/addProject";
import Template from "./UtilityTemplate/index";
import AddUtility from "./UtilityTemplate/Utility/utility";
import Device from "./Device/index";
import AddDevice from "./Device/NewDevice/NewDevice";
import DevcieMap from "./ProjectDevice/index";
import AssignDevice from "./ProjectDevice/AssignDevice/AssignDevice";
import ManufactureName from "./Manufacturer/index";
import AddManufacture from "./Manufacturer/NewManufacturer/NewManufacturer";
import SyncJobs from "./Syncjobs/index";

const Components = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/dataDisplay`} component={DataDisplay} />
    <Route path={`${match.url}/dataEntry`} component={DataEntry} />
    <Route path={`${match.url}/feedback`} component={Feedback} />
    <Route path={`${match.url}/general`} component={General} />
    <Route path={`${match.url}/navigation`} component={Navigation} />
    <Route path={`${match.url}/others`} component={Others} />
    <Route path={`${match.url}/project`} component={Project} />
    <Route path={`${match.url}/add/project`} component={AddProject} />
    <Route path={`${match.url}/edit/project/:id`} component={AddProject} />
    <Route path={`${match.url}/utility`} component={Template} />
    <Route path={`${match.url}/add/utility`} component={AddUtility} />
    <Route path={`${match.url}/device`} component={Device} />
    <Route path={`${match.url}/add/device`} component={AddDevice} />
    <Route path={`${match.url}/mapdevice`} component={DevcieMap} />
    <Route path={`${match.url}/assign/device`} component={AssignDevice} />
    <Route path={`${match.url}/manufacture`} component={ManufactureName} />
    <Route path={`${match.url}/add/manufacture`} component={AddManufacture} />
    <Route path={`${match.url}/syncjob`} component={SyncJobs} />
    <Route path={`${match.url}/table`} component={Table} />
  </Switch>
);

export default Components;
