import React, { Component } from "react";
import { AutoComplete, Button, Card, Form, Icon, Input, Row, Select, Tooltip, Switch, Timeline, Radio } from "antd";

import axios from 'axios'
const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;




class Registration extends Component {
  state = {
    utility: [],
    maufactures: []
  };

  componentDidMount() {
    axios.get('/utility')
      .then(response => {
        this.setState({ utility: response.data.data })
      })
      .catch(error => {
        console.log(error);
      });

    axios.get('/manufactures')
      .then(response => {
        this.setState({ maufactures: response.data.data })
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        delete values.prefix
        axios.post('/device', values)
          .then(response => {
            this.props.history.push(`/components/device`);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '86',
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    );
    const nodeType = ["Single-Node", "Multi-Node"];

    return (
      <Card className="gx-card" title="Register Device">
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={(
              <span>
                Mac_id&nbsp;
                <Tooltip title="Mac_id is device unique identity number">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            )}
          >
            {getFieldDecorator('Mac_id', {
              rules: [{ required: true, message: 'Please input the Device Mac id', whitespace: true }],
            })(
              <AutoComplete
                placeholder="Mac_id"
              >
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Device Id"
          >
            {getFieldDecorator('deviceId', {
              rules: [{ required: true, message: 'Please enter Device Id!' }],
            })(
              <AutoComplete
                placeholder="Device Id"
              >
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Select Node"
          >
            {getFieldDecorator('nodeType', {
              rules: [{ required: true, }],
            })(
              <AutoComplete
            >
               <Radio.Group
                options={nodeType}
                style={{ "margin-top": "10px" }}
                onChange={e => this.onClickMaintenanceMode(e)}
                name={"nodeType"}
              />
            </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Version"
          >
            {getFieldDecorator('version', {
              rules: [{ required: true, message: 'Please enter version!' }],
            })(
              <AutoComplete
                placeholder="Version"
              >
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Manufacturer"
          >
            {getFieldDecorator('Manufacturer', {
              rules: [{ required: true }],
            })(
              <Select
                mode="single"
                showSearch
                placeholder="Select Manufacture"
                optionFilterProp="children"
              >
                {this.state.maufactures.map((list, i) => {
                  return <Option value={list._id}>{list.manufacture}</Option>;
                })}
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Select Utilities
              </span>
            }
          >
            {getFieldDecorator("utilityData", {
              rules: [
                {
                  required: true,

                }
              ]
            })(
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Blocks"
                optionFilterProp="children"
              >
                {this.state.utility.map((list, i) => {
                  return <Option value={list._id}>{list.name}</Option>;
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">Create</Button>
          </FormItem>
        </Form>
      </Card>
    );
  }

}

const RegistrationForm = Form.create()(Registration);
export default RegistrationForm;
