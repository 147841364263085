import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, Button, Popconfirm, EditableContext, Dropdown, Icon, Card, Menu, Badge } from 'antd';
import axios from 'axios'

const menu = (
  <Menu>
    <Menu.Item>Action 1</Menu.Item>
    <Menu.Item>Action 2</Menu.Item>
  </Menu>
);
class App extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    data: [],

  };
  componentDidMount() {
    axios.get('/project')
      .then(response => {
        this.setState({ data: response.data.data })
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  edit(key) {
    var url = window.location.href.split("/")
    var len = url.length
    url[len-1] = "edit/project"
    var getUrl =  url.splice(3,6).join("/")
    this.props.history.push(`/${getUrl}/${key}`);
   // this.props.history.push(`/editproject/${key}`);
  //  this.setState({ editingKey: key });
  }
  expandedRowRender = row => {
    var dataArr = [];
    if(row.template.length>0){
      console.log("DDDDD",row.template)
      dataArr = row.template
    }
    const columns = [
      { title: 'Utility Name', dataIndex: 'utilityId.name', key: 'name' },
      { title: 'Code', dataIndex: 'utilityId.code', key: 'code' },
      { title: 'Mode', dataIndex: 'utilityType', key: 'mode' },
    ];

    return <Table columns={columns} dataSource={dataArr} pagination={false} />;
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        filteredValue: filteredInfo.name || null,
        onFilter: (value, record) => record.name.includes(value),
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        filteredValue: filteredInfo.address || null,
        onFilter: (value, record) => record.address.includes(value),
        sorter: (a, b) => a.address.length - b.address.length,
        sortOrder: sortedInfo.columnKey === 'address' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Website',
        dataIndex: 'website',
        key: 'website',
        filteredValue: filteredInfo.website || null,
        onFilter: (value, record) => record.website.includes(value),
        sorter: (a, b) => a.website.length - b.website.length,
        sortOrder: sortedInfo.columnKey === 'website' && sortedInfo.order,
        ellipsis: true,
      }, {
        title: 'Action',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <span>
              <a  onClick={() => this.edit(record._id)}>
                Edit
             </a>
            </span>
          )
        },
      }

    ];
    return (
      <div>
        <Card className="gx-card" title="All Projects">
          <div className="table-operations">
            <Button onClick={this.clearAll}>Clear filters and sorters</Button>
          </div>

          <Table columns={columns} bordered dataSource={this.state.data} expandedRowRender={(row)=>this.expandedRowRender(row)} onChange={this.handleChange} />
        </Card>
      </div>
    );
  }
}




export default withRouter(App)
