import React from "react";
import {Col, Row,Button} from "antd";
import ProjectList from "./ProjectsList/ProjectList"
class Crypto extends React.Component{
  handleClick = this.handleClick.bind(this)
  handleClick(e){
    var url = window.location.href.split("/")
    var len = url.length
    url[len-1] = "add/project"
    var getUrl =  url.splice(3,6).join("/")
    this.props.history.push(`/${getUrl}`);
  }
  render(){
    return (
      <div>
         <Button type="primary" onClick={this.handleClick} style={{float: 'right','z-index' :'1','top':'10px'}}>Add Project</Button>
         <div>
         <ProjectList/>
         </div>

      </div>

    );
  }
};

export default Crypto;
