import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  change_sync_status,
  get_all_jobs,
} from "../../../appRedux/actions/Syncjob";
import { fetchStart } from "../../../appRedux/actions/Common";

import { Button, Card, Modal } from "antd";

const confirm = Modal.confirm;

const ConfirmationModal = ({ record, ...props }) => {
  function showConfirm() {
    confirm({
      title: "Do you Want to change status to complete ?",
      content: "Project Name",
      onOk() {
        props.fetchStart();
        props.change_sync_status(record);
        props.get_all_jobs();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  const isDisabled = record.syncStatus == "completed" ? true : false;
  return (
    <Button type="primary" disabled={isDisabled} onClick={showConfirm}>
      Change Status
    </Button>
  );
};
export default connect(
  (state) => ({
    loading: state.common.loading,
  }),
  { fetchStart, change_sync_status, get_all_jobs }
)(ConfirmationModal);
