import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_ALL_JOBS, UPDATE_SYNC_STATUS } from "../../constants/ActionTypes";

import { get_all_jobs_success } from "../actions/Syncjob";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";

import axios from "axios";

const fetchJobs = async () =>
  await axios
    .get("/getsyncjobs")
    .then((res) => res.data)
    .catch((error) => error.response);

const putJob = async (data) =>
  await axios
    .post(`/update/job/${data._id}`)
    .then((res) => res.data)
    .catch((error) => error);

function* getJobs() {
  try {
    const data = yield call(fetchJobs);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_all_jobs_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* updateSyncStatus({ payload }) {
  try {
    const data = yield call(putJob, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* getAllJobs() {
  yield takeEvery(GET_ALL_JOBS, getJobs);
}
export function* updateSyncJob() {
  yield takeEvery(UPDATE_SYNC_STATUS, updateSyncStatus);
}

export default function* rootSaga() {
  yield all([fork(getAllJobs), fork(updateSyncJob)]);
}
